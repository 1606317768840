import React from 'react';
import './Home.css';

import HeroComponent from '../components/Hero/Hero';
import AboutMe from '../components/AboutMe/AboutMe';
import Experience from '../components/Experience/Experience';
import Education from '../components/Education/Education';
import Skills from '../components/Skills/Skills';
import { Container } from 'react-bootstrap';
import Contact from '../components/ContactMe/Contact';

export default function Home() {
  return (
    <div style={{ backgroundColor: '#f9f9f9'}}>
      <HeroComponent/>
      <Container fluid="sm">
        <AboutMe/>
        <Experience/>
        <Education/>  
        <Skills/>
      </Container>
      <Contact/>
    </div>
  );
}
