import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AboutMe = () => {
    const { t } = useTranslation();

    return (
        <Row className="justify-content-center">
            <Col md={8} lg={10}>
                <div style={{ marginBottom: '2rem' }}>
                    <h3 style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: '#4A148C',
                        textTransform: 'uppercase',
                        marginBottom: '1rem'
                    }}>
                        {t('about_me.title')}
                    </h3>
                    <Card 
                        style={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                            padding: '2rem',
                            margin: '0 auto',
                        }}
                    >
                        <Card.Body style={{ textAlign: 'center' }}>
                            <Card.Text style={{ fontSize: '1.1rem', color: '#130164', lineHeight: '1.6' }} dangerouslySetInnerHTML={{ __html: t('about_me.description') }} />
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    );
}

export default AboutMe;
