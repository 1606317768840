import React, { useState } from "react";
import ProfilePicture from '../../assets/profile.jpg';

import { Col, Row, Image } from "react-bootstrap";
import './Hero.css';
import { useTranslation } from "react-i18next";
import LanguageSelector from "./components/LanguageSelector";

const HeroComponent = () => {
    const { t } = useTranslation();
    const [isHovered, setIshovered] = useState(false);

    function handleOnMouseOver() {
        setIshovered(true);
        setTimeout(() => {
            setIshovered(false);
        }, 3000);
    }

    return (
        <div style={{ paddingBottom: '3rem' }}>
            <div className="area">
                <Col style={{ display: 'flex', position: 'absolute', top: '1rem', right: '1rem', justifyContent: 'end', paddingRight: '1rem' }}>
                    <LanguageSelector />
                </Col>
                <Row style={{ padding: '40px' }}>
                    <Col md={5} xs={8} className="d-flex justify-content-center" style={{ height: '100%' }}>
                        <Image src={ProfilePicture} roundedCircle style={{
                            width: '30vh',
                            height: '30vh', 
                            padding: '20px'
                        }} />
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center">
                        <div style={{ color: "#FFFFFF" }}>
                            <h1>{t("name")}</h1>
                            <p>{t("description")}</p>
                            <div className="hero-buttons">
                                <a href="#contact" className="btn btn-primary">{t("contact_me")}</a>
                                <a href="#projects" className="btn btn-secondary" onMouseOver={handleOnMouseOver}>{isHovered ? t("projects_loading") : t("projects")}</a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <ul className="circles">
                    {Array.from({ length: 20 }).map((_, index) => (
                        <li key={index}></li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default HeroComponent;
