import React, { useState, useRef } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { IconGithub, IconLinkedin, IconMail, IconMapPin, IconPhone } from '../Icons'
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const form = useRef();
    const { t } = useTranslation(); // Hook de i18next para obtener las traducciones
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        from_empresa: '',
        message: ''
    });
    const [emailSended, setEmailSended] = useState(false);
    const [error, setError] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_r002i0x', 'template_ksw17r9', form.current, {
                publicKey: 'HHGTNgfGMbC5cYEzz',
            })
            .then(
                () => {
                    setEmailSended(true);
                },
                (error) => {
                    console.error('Error sending email:', error);
                    setError(t('contact.error'));
                },
            );
    };

    function resetForm() {
        setFormData({
            from_name: '',
            from_email: '',
            from_empresa: '',
            message: ''
        });
        setError();
        setEmailSended(false);
    }

    return (
        <div style={{ backgroundColor: '#161616', justifyContent: 'center', padding: '2rem 0', alignItems: 'center' }} id="contact">
            <h2 className="text-center mb-4" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                {t('contact.title')}
            </h2>
            <div>
                <Row className="justify-content-center">
                    {/* Contact Information */}
                    <Col xs={12} md={5} xl={4}>
                        <Card className="mb-4" style={{ borderRadius: '12px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                            <Card.Body>
                                <Card.Text style={{ fontSize: '1.1rem', color: '#666' }}>
                                    {t('contact.info')}
                                </Card.Text>
                                <div className="contact-details">
                                    <div className="contact-item mb-3">
                                        <IconMail style={{ color: '#000000' }} /> <span>dsanroga@gmail.com</span>
                                    </div>
                                    <div className="contact-item mb-3">
                                        <IconPhone style={{ color: '#000000' }} /> <span>+34 637 203 159</span>
                                    </div>
                                    <div className="contact-item mb-3">
                                        <IconMapPin style={{ color: '#000000' }} /> <span>Barcelona, España</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <div className="social-links d-flex justify-content-center" style={{ margin: '1rem' }}>
                            <a href="https://es.linkedin.com/in/daniel-sanroman-garcia-4a757417a" aria-label="LinkedIn" className="mr-3">
                                <IconLinkedin size={30} style={{ color: '#000000' }} />
                            </a>
                            <a href="https://github.com/dsanro" aria-label="GitHub">
                                <IconGithub size={30} style={{ color: '#000000' }} />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={7} xl={6}>
                        <Card style={{ borderRadius: '12px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
                            <Card.Body>
                                {error && <div style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}>{error}</div>}
                                {emailSended ? <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: '1rem', alignItems: 'center' }}>
                                    <h3 style={{ color: '#4A148C', textAlign: 'center' }}>
                                        {t('contact.thankYou')}
                                    </h3>
                                    <button onClick={resetForm} style={{
                                        backgroundColor: '#4A148C', borderRadius: '10px', color: 'white', padding: '12px 20px',
                                        border: 'none', fontWeight: 'bold', cursor: 'pointer', fontSize: '16px'
                                    }}>
                                        {t('contact.sendAnother')}
                                    </button>
                                </div> : (
                                    <form ref={form} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <input
                                            type="text"
                                            id="from_name"
                                            name="from_name"
                                            value={formData.from_name}
                                            onChange={handleChange}
                                            placeholder={t('contact.name')}
                                            required
                                            style={{
                                                borderRadius: '10px', padding: '5px', border: '1px solid #ccc', margin: '5px 0', fontSize: '16px',
                                            }}
                                        />
                                        <input
                                            type="text"
                                            id="from_empresa"
                                            name="from_empresa"
                                            value={formData.from_empresa}
                                            onChange={handleChange}
                                            placeholder={t('contact.company')}
                                            required
                                            style={{
                                                borderRadius: '10px', padding: '5px', border: '1px solid #ccc', margin: '5px 0', fontSize: '16px',
                                            }}
                                        />
                                        <input
                                            type="email"
                                            id="from_email"
                                            name="from_email"
                                            value={formData.from_email}
                                            onChange={handleChange}
                                            placeholder={t('contact.email')}
                                            required
                                            style={{
                                                borderRadius: '10px', padding: '5px', border: '1px solid #ccc', margin: '5px 0', fontSize: '16px',
                                            }}
                                        />
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder={t('contact.message')}
                                            rows="4"
                                            required
                                            style={{
                                                borderRadius: '10px', padding: '5px', border: '1px solid #ccc', margin: '5px 0', fontSize: '16px',
                                            }}
                                        />
                                        <input
                                            type="submit"
                                            value={t('contact.submit')}
                                            style={{
                                                backgroundColor: '#4A148C', borderRadius: '10px', color: 'white', padding: '12px 20px',
                                                border: 'none', fontWeight: 'bold', cursor: 'pointer', fontSize: '16px',
                                            }}
                                        />
                                    </form>)}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Contact;
