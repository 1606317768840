import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Education = () => {
    const { t } = useTranslation();

    return (
        <div style={{ paddingTop: '2rem' }}>
            <h3 
                style={{
                    textAlign: 'center', 
                    fontWeight: 'bold', 
                    color: '#4A148C', 
                    textTransform: 'uppercase', 
                    marginBottom: '2rem'
                }}
            >
                {t('education.title')}
            </h3>
            <Row className="justify-content-center">
                {t('education.cards', { returnObjects: true }).map((card, index) => (
                    <Col xs={12} md={6} xl={4} key={index}>
                        <Card 
                            style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '10px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                marginBottom: '1rem'
                            }}
                        >
                            <Card.Body>
                                <Card.Title 
                                    style={{
                                        fontWeight: 'bold', 
                                        color: '#130164', 
                                        marginBottom: '0.5rem'
                                    }}
                                >
                                    {card.title}
                                </Card.Title>
                                <Card.Text style={{ color: '#9C27B0', fontSize: '1rem' }}>
                                    {card.date}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Education;
