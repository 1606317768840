import React, { useState } from "react";
import { Form } from "react-bootstrap";

import "./LanguageSelector.css";
import i18n from "../../../i18n";

const LanguageSelector = () => {
    const [language, setLanguage] = useState(i18n.language);

    const handleChange = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Form.Group controlId="languageSelect" className="language-selector">
            <Form.Select
                value={language}
                onChange={handleChange}
                className="custom-select"
            >
                <option value="es">🇪🇸 Español</option>
                <option value="en">🇬🇧 English</option>
                <option value="ca">🇪🇸 Català</option>
            </Form.Select>
        </Form.Group>
    );
};

export default LanguageSelector;
