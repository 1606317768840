import React from 'react';
import { Row, Card, Col, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Experience = () => {

    const { t } = useTranslation();

    function getDiffWorkTime() {
        const firstDate = new Date("09/11/2024");
        const secondDate = new Date();

        const yearDiff = secondDate.getFullYear() - firstDate.getFullYear();
        const monthDiff = secondDate.getMonth() - firstDate.getMonth();

        const totalMonthsDiff = yearDiff * 12 + monthDiff;

        return totalMonthsDiff;
    }

    return (
        <div style={{ padding: '2rem' }}>
            <h3 style={{
                marginBottom: '1.5rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: '#130164',
                textAlign: 'center'
            }}>
                {t('experience.title')}
            </h3>
            <Row>
                {[
                    {
                        title: t('experience.jobs.basetisSeniorTitle'),
                        subtitle: t('experience.jobs.basetisSeniorSubtitle'),
                        description: t('experience.jobs.basetisSeniorDescription'),
                        skills: t('experience.jobs.basetisSeniorSkills', { returnObjects: true })
                    },
                    {
                        title: t('experience.jobs.basetisPMTitle'),
                        subtitle: t('experience.jobs.basetisPMSubtitle'),
                        description: t('experience.jobs.basetisPMDescription'),
                        skills: t('experience.jobs.basetisPMSkills', { returnObjects: true })
                    },
                    {
                        title: t('experience.jobs.volkswagenTitle'),
                        subtitle: t('experience.jobs.volkswagenSubtitle'),
                        description: t('experience.jobs.volkswagenDescription'),
                        skills: t('experience.jobs.volkswagenSkills', { returnObjects: true })
                    },
                    {
                        title: t('experience.jobs.minsaitTitle'),
                        subtitle: t('experience.jobs.minsaitSubtitle', { months: getDiffWorkTime() }),
                        description: t('experience.jobs.minsaitDescription'),
                        skills: t('experience.jobs.minsaitSkills', { returnObjects: true })
                    }
                ].map((job, index) => (
                    <Col md={6} xl={3} key={index} style={{ padding: '0.75rem' }}>
                        <Card
                            style={{
                                background: 'linear-gradient(135deg, #130164, #38005e)',
                                color: 'white',
                                border: 'none',
                                borderRadius: '12px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out'
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = 'scale(1.05)';
                                e.currentTarget.style.boxShadow = '0px 6px 15px rgba(0, 0, 0, 0.2)';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = 'scale(1)';
                                e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.15)';
                            }}
                        >
                            <Card.Body>
                                <Card.Title style={{ fontSize: '1.1rem', fontWeight: 'bold', textTransform: 'uppercase' }}>
                                    {job.title}
                                </Card.Title>
                                <Card.Subtitle className="mb-2" style={{ color: '#D1C4E9', fontSize: '0.9rem' }}>
                                    {job.subtitle}
                                </Card.Subtitle>
                                <Card.Text style={{ fontSize: '0.95rem', marginBottom: '1rem' }}>
                                    {job.description}
                                </Card.Text>
                                <ListGroup variant="flush" style={{ background: 'transparent' }}>
                                    {Array.isArray(job.skills) && job.skills.map((skill, i) => (
                                        <ListGroup.Item key={i} style={{ background: 'transparent', color: '#EDE7F6', borderColor: 'rgba(255, 255, 255, 0.2)' }}>
                                            {skill}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Experience;
